<template>
  <a-spin :spinning="spinShow">
    <a-form layout="horizontal" :form="form">
      <a-form-item label="所属单位"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-select placeholder="请选择所属单位"
                  v-decorator="['organization.id', {rules: [{ required: true, message: '请选择所属单位'}]}]">
          <a-select-option
            v-for="item in allUnits"
            :key="item.id"
            :value="item.id"
            :label="item.fullName">
            {{ item.fullName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="安全四色图"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <qiniu-image-upload
          ref="qiniuImageRef" @getImageData="getImageData"
          :multiSelection="true"
          type="edit"
          :items="formItem.images"
        ></qiniu-image-upload>
      </a-form-item>
      <a-form-item label="Gson数据"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-textarea v-decorator="['colorImageOneContent']" :rows="16"
                    placeholder="请输入安全四色图gson数据"></a-textarea>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '../../../api/service.url'
  import { riskHomeConfigVO } from './common/riskHomeConfigVO'
  import QiniuImageUpload from '../../../components/QiniuUpload/QiniuImageUpload'

  export default {
    name: 'noticeForm',
    props: {
      type: {
        type: String,
        default: 'edit'
      }
    },
    components: { QiniuImageUpload },
    data () {
      return {
        spinShow: false,
        ifSub: false,
        form: this.$form.createForm(this),
        formItem: riskHomeConfigVO(),
        labelCol: { span: 4 },
        wrapperCol: { span: 19 }
      }
    },
    computed: {
      allUnits () {
        return this.$store.getters.allUnits
      }
    },
    methods: {
      loadData (id) {
        //清空表单数据
        this.formItem = riskHomeConfigVO()
        this.spinShow = true
        this.$http(this, {
          url: SERVICE_URLS.dpc.homeConfigApi.view,
          params: {
            id: id
          },
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            this.$nextTick(() => {
              this.setFields(data.body)
            })
            //赋值接收对象
            this.spinShow = false
          }
        })
      },
      setFields (values) {
        Object.assign(this.formItem, values)
        /*图片初始化展示处理赋值Ids*/
        values.images.forEach(item => {
          this.formItem.imageIds.push(item.id)
        })
        this.form.setFieldsValue({
          'colorImageOneContent': this.formItem.config.colorImageOneContent,
          'organization.id': this.formItem.config.organization.id
        })
      },
      setVOFields (values) {
        this.formItem.config.colorImageOneContent = values.colorImageOneContent
        this.formItem.config.organization.id = values.organization.id
      },
      submitAdd () {
        this.form.validateFields(
          (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$http(this, {
                url: SERVICE_URLS.dpc.homeConfigApi.create,
                data: this.formItem,
                success: (data) => {
                  //清空表单数据
                  this.formItem = riskHomeConfigVO()
                  this.$emit('addSuccess', data.body)
                },
                error: () => {
                  this.$emit('addError')
                }
              })
            }
          })
      },
      submitEdit () {
        this.form.validateFields(
          (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$http(this, {
                url: SERVICE_URLS.dpc.homeConfigApi.update,
                data: this.formItem,
                params: {
                  id: this.formItem.config.id
                },
                success: (data) => {
                  //清空表单数据
                  this.formItem = riskHomeConfigVO()
                  this.$emit('editSuccess', data.body)
                }
              })
            }
          }
        )
      },
      /*上传图片回调方法*/
      getImageData (data) {
        this.formItem.imageIds = []
        data.forEach(item => {
          this.formItem.imageIds.push(item.id)
        })
      }
    }
  }
</script>