export const columns = () => {
  return [
    {
      title: '序号',
      width: '80px',
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '单位',
      align: 'left',
      width: '100',
      dataIndex: 'organization.fullName'
    },
    {
      title: '操作',
      align: 'center',
      width: '160px',
      scopedSlots: { customRender: 'action' }
    }
  ]
}
