<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">首页配置信息列表</h1>
    </div>
    <div slot="extra">
      <a-button icon="plus" type="primary" @click="handleAdd">添加配置</a-button>
    </div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-form-item>
            <a-select style="width: 360px;" placeholder="请选择所属单位"
                      allowClear showSearch optionFilterProp="title"
                      v-model="searchParams.organizationId"
                      @change="search">
              <a-select-option
                v-for="item in allUnits"
                :key="item.id"
                :title="item.name"
                :value="item.id"
                :label="item.name">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="f-r m-r-none">
            <a-button type="primary" @click="search" icon="search">查询</a-button>
            <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-table bordered size="default"
               :rowKey="record => record.id"
               :columns="columns"
               @change="tableChange"
               :pagination="pagination"
               :dataSource="tableData"
               :loading="tableLoading">
        <template slot="_index" slot-scope="text, record, index">{{index+1}}</template>
        <template slot="action" slot-scope="text, record">
          <a-button @click="handleEdit(record.id)" shape="circle" icon="edit"
                    size="small"></a-button>
          <a-divider type="vertical"/>
          <a-button type="danger" @click="handleDelete(record.id)" shape="circle" icon="delete"
                    size="small"></a-button>
        </template>
      </a-table>
      <a-modal title="添加首页配置"
               width="800px"
               :visible="addModal"
               :maskClosable="false"
               @ok="handleAddOk"
               @cancel="closeAddModal">
        <risk-config-form ref="addRef" type="add"
                          @addSuccess="addSuccess"
                          @addError="addError">
        </risk-config-form>
      </a-modal>
      <a-modal title="修改首页配置"
               width="800px"
               :visible="editModal"
               :maskClosable="false"
               @ok="handleEditOk"
               @cancel="closeEditModal">
        <risk-config-form ref="editRef" type="edit"
                          @editSuccess="editSuccess"
                          @editError="editError">
        </risk-config-form>
      </a-modal>
    </a-card>
  </page-layout>
</template>

<script>
  import PageLayout from '@/components/page/PageLayout'
  import entityCRUD from '@/views/common/mixins/entityCRUD'
  import SERVICE_URLS from '../../../api/service.url'
  import { columns } from './common/common'
  import RiskConfigForm from './Form'

  export default {
    name: 'riskHomeConfig',
    mixins: [entityCRUD],
    components: { RiskConfigForm, PageLayout },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.dpc.homeConfigApi,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          organizationId: null
        },
        initColumns: columns()
      }
    },
    computed: {
      allUnits () {
        return this.$store.getters.allUnits
      }
    }
  }
</script>
